import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import { FiMenu, FiX } from 'react-icons/fi';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <motion.header
      className="p-4 flex justify-between items-center"
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      <h1 className="text-4xl font-bold">samsara-ai</h1>
      <nav className="hidden md:block">
        {/* Menu para desktop */}
        <ul className="flex space-x-4">
          <li>
            <Link to="inicio" smooth={true} duration={500} className="hover:underline cursor-pointer">
              Início
            </Link>
          </li>
          <li>
            <Link to="sobre" smooth={true} duration={500} className="hover:underline cursor-pointer">
              Sobre
            </Link>
          </li>
          <li>
            <Link to="contato" smooth={true} duration={500} className="hover:underline cursor-pointer">
              Contato
            </Link>
          </li>
        </ul>
      </nav>
      <div className="md:hidden">
        <button onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>
      {menuOpen && (
        <nav className="absolute top-full left-0 w-full bg-purple-900 md:hidden">
          <ul className="flex flex-col items-center">
            <li>
              <Link
                to="inicio"
                smooth={true}
                duration={500}
                className="block py-2"
                onClick={() => setMenuOpen(false)}
              >
                Início
              </Link>
            </li>
            <li>
              <Link
                to="sobre"
                smooth={true}
                duration={500}
                className="block py-2"
                onClick={() => setMenuOpen(false)}
              >
                Sobre
              </Link>
            </li>
            <li>
              <Link
                to="contato"
                smooth={true}
                duration={500}
                className="block py-2"
                onClick={() => setMenuOpen(false)}
              >
                Contato
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </motion.header>
  );
}

export default Header;