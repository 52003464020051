import React from 'react';
import { motion } from 'framer-motion';

function VideoSection() {
  return (
    <section
      className="flex justify-center items-center p-8 bg-transparent"
      id="inicio"
    >
      <motion.video
        src="your_video.mp4"
        controls
        className="w-full max-w-3xl rounded-lg shadow-lg"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
      />
    </section>
  );
}

export default VideoSection;