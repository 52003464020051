import React from 'react';
import Particles from 'react-tsparticles';

function ParticlesBackground() {
  return (
    <Particles
      id="tsparticles"
      options={{
        background: { color: 'transparent' },
        fpsLimit: 60,
        particles: {
          color: { value: '#ffffff' },
          links: { enable: true, color: '#ffffff', distance: 150 },
          move: { enable: true, speed: 1 },
          number: { value: 50 },
          opacity: { value: 0.5 },
          shape: { type: 'circle' },
        },
      }}
    />
  );
}

export default ParticlesBackground;