import React from 'react';
import { FaRobot, FaMusic, FaImage, FaVideo } from 'react-icons/fa';
import { motion } from 'framer-motion';

function ServicesSection() {
  function ServiceCard({ icon, title }) {
    return (
      <motion.div
        className="bg-purple-700 p-6 rounded-lg text-center cursor-pointer"
        whileHover={{
          scale: 1.05,
          boxShadow: '0px 0px 15px rgba(155, 89, 182, 0.5)',
        }}
        transition={{ duration: 0.3 }}
      >
        <div className="mb-4 text-gemini-purple">{icon}</div>
        <h3 className="text-xl font-semibold">{title}</h3>
      </motion.div>
    );
  }

  return (
    <section className="p-8 bg-purple-800">
      <h2 className="text-3xl font-semibold text-center mb-8">
        O que a samsara-ai oferece
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <ServiceCard icon={<FaRobot size={40} />} title="Chatbots Personalizados" />
        <ServiceCard icon={<FaMusic size={40} />} title="Geração de Música" />
        <ServiceCard icon={<FaImage size={40} />} title="Criação e Edição de Imagens" />
        <ServiceCard icon={<FaVideo size={40} />} title="Produção de Vídeos" />
        {/* Adicione mais cards conforme necessário */}
      </div>
      <motion.section
        className="p-8 bg-purple-800"
        id="services"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        {/* Conteúdo da seção */}
      </motion.section>
    </section>
  );
}

export default ServicesSection;