import ParticlesBackground from './components/ParticlesBackground';
import React from 'react';

import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

function App() {
  return (
    <div className="relative min-h-screen text-white overflow-hidden bg-gradient-to-br from-gemini-purple to-purple-900">
      <ParticlesBackground />
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;