import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Testimonials() {
  const testimonials = [
    {
      quote: "A samsara-ai transformou minha forma de trabalhar com IA.",
      author: "Ana Paula",
    },
    {
      quote: "Incrível! As possibilidades são infinitas.",
      author: "Carlos Eduardo",
    },
    // Mais depoimentos...
  ];

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
  };

  return (
    <motion.section
      className="p-8 bg-purple-900 text-center"
      id="testimonials"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-3xl font-semibold mb-6">Depoimentos</h2>
      <Slider {...settings}>
        {testimonials.map((t, index) => (
          <div key={index} className="px-4">
            <p className="text-xl italic">"{t.quote}"</p>
            <p className="mt-4 font-bold">- {t.author}</p>
          </div>
        ))}
      </Slider>
    </motion.section>
  );
}

export default Testimonials;