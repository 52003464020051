import React from 'react';
import { motion } from 'framer-motion';

function MentorCallToAction() {
  return (
    <motion.section
      className="p-8 text-center"
      id="sobre"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-3xl font-semibold mb-4">Encontre o Mentor Ideal</h2>
      <p className="mb-6">
        Aproveite ao máximo as possibilidades que as IAs oferecem hoje para qualquer atividade.
      </p>
      <a href="#contato">
        <button className="bg-white text-gemini-purple font-bold py-2 px-6 rounded-full hover:bg-gray-200 transition duration-300">
          Comece Agora
        </button>
      </a>
    </motion.section>
  );
}

export default MentorCallToAction;