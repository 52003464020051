import React from 'react';
import VideoSection from './VideoSection';
import MentorCallToAction from './MentorCallToAction';
import ServicesSection from './ServicesSection';
import Testimonials from './Testimonials';


function Main() {
  return (
    <main>
      <VideoSection />
      <MentorCallToAction />
      <ServicesSection />
      <ServicesSection />
<Testimonials />

    </main>
  );
  
}

export default Main;